.page-finish {
  background: #fcfc30;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .profile {
    width: 100%;
    background-color: #F17EAC;
    padding: 6rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 85%;

      @media only screen and (min-width: 600px) {
        width: 45%;
      }

      @media only screen and (min-width: 1200px) {
        width: 35%;
      }
    }

    h4 {
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      font-family: "BancoDoBrasilTitulosBold700";
      color: #fcfc30;
  
      @media only screen and (min-width: 600px) {
        font-size: 40px;
        line-height: 40px;
      }
    }
    h2 {
      font-size: 64px;
      line-height: 64px;
      text-align: center;
      font-family: "BancoDoBrasilTitulosBold700";
      color: #fcfc30;
      @media only screen and (min-width: 600px) {
        font-size: 96px;
        line-height: 96px;
        width: 100%;
        br {
          display: none;
        }
      }
    }

    .description {
      font-size: 16px;
      line-height: 16px;
      color: #fff;
      width: 100%;
      margin-top: 2rem;
      margin-bottom: -2rem;
      font-family: "BancoDoBrasilTextosMedium";
      
      @media only screen and (min-width: 600px) {
        font-size: 24px;
        line-height: 24px;
        width: 50%;
        margin-bottom: 0;
      }
    }
  }

  h4 {
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #F17EAC;

    @media only screen and (min-width: 600px) {
      font-size: 40px;
      line-height: 40px;
    }
  }
  h2 {
    font-size: 64px;
    line-height: 64px;
    text-align: center;
    color: #F17EAC;
    @media only screen and (min-width: 600px) {
      font-size: 96px;
      line-height: 96px;
      width: 100%;
      br {
        display: none;
      }
    }
  }

  .invista {
    background-color: #54DCFC;
    padding: 6rem 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;

      img {
        width: 70%;
      }
    }
    
    h2 {
      color: #465EFF;
      font-size: 28px;
      line-height: 32px;
      margin: 0;
      font-family: "BancoDoBrasilTitulosLightItalic";

      @media only screen and (min-width: 600px) {
        font-size: 60px;
        line-height: 64px;
      }

      &.right {
        margin-left: 3rem;
        margin-right: -2.5rem;
        white-space: nowrap;
        font-family: "BancoDoBrasilTitulosBold700";

        @media only screen and (min-width: 600px) {
          margin-left: 6rem;
        }
      }
    }

    p {
      margin-top: 2rem;
      font-size: 18px;
      color: #465EFF;

      b {
        font-family: "BancoDoBrasilTitulosBold700";
      }

      @media only screen and (min-width: 600px) {
        font-size: 20px;
        width: 50%;
        text-align: center;
      }
    }
  }

  .invesTalk {
    background-color: #465EFF;
    padding: 6rem 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: #fff;
      font-family: "BancoDoBrasilTitulosBold700";
      font-size: 36px;
      margin-bottom: 1rem;

      @media only screen and (min-width: 600px) {
        font-size: 48px;
      }
    }

    .promotor {
      font-family: "BancoDoBrasilTextosMedium";
      margin-top: 2.5rem;
      font-size: 18px;

      @media only screen and (min-width: 600px) {
        font-size: 24px;
        width: 50%;
      }
    }

    h6 {
      color: #FCFC30;
      font-size: 32px;
      line-height: 40px;

      @media only screen and (min-width: 600px) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .logo {
      display: flex;
      flex-direction: row;
      gap: 0.2rem;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    video {
      margin-top: 3rem;
      width: 85%;
    }
  }

  .card {
    background-color: #FCFC30;
    padding: 6rem 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      color: #465EFF;
      font-family: "BancoDoBrasilTitulosBold700";
      font-size: 48px;
      line-height: 48px;
    }

    h3 {
      color: #465EFF;
      font-family: "BancoDoBrasilTitulosBold700";
      font-size: 36px;
      line-height: 42px;
    }

    img {
      width: 90%;

      @media only screen and (min-width: 600px) {
        width: 45%;
      }
    }

    button {
      background-color: #465EFF;
      outline: none;
      border: none;
      border-radius: 10px;
      color: #FCFC30;
      font-size: 24px;
      padding: 0.5rem 2rem;
      margin-bottom: 3rem;
      width: 75%;

      @media only screen and (min-width: 600px) {
        border-radius: 20px;
        padding: 1rem 4rem;
        margin-bottom: 6rem;
        width: 35%;
      }
    }

    .subTitle {
      font-family: "BancoDoBrasilTextosMediumItalic";
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #465EFF;
    }

    .benefits {
      padding: 0;
      margin-top: 3rem;
      display: grid;
      grid-auto-flow: row dense;
      grid-template-columns: repeat(auto-fit, minmax(280px, 48%));
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;
      margin-bottom: 4rem;
    }

    .card-benefit {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      position: relative;

      .card-benefit-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 2rem 0.5rem;
        border: 2px solid #465EFF;
        border-radius: 10px;
        position: relative;
        background: #fcfc30;
        z-index: 2;
      }
  
      p {
        font-size: 16px !important;
        margin: 0 0.5rem 0.5rem !important;
        color: #465EFF;
      }
  
      svg {
        width: 48px;
        height: 48px;
  
        @media only screen and (min-width: 600px) {
          width: 72px;
          height: 72px;
        }
      }
  
      &::after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 97%;
        height: 97%;
        background: #465EFF;
        border: 1px solid #465EFF;
        border-radius: 10px;
        bottom: -10px;
        right: -8px;
      }
    }
  }

  .gift {
    background-color: #00EBD0;
    padding: 6rem 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .gift-image {
      width: 150% !important;

      @media only screen and (min-width: 600px) {
        width: 125% !important;
      }
    }

    .carousel-item {
      width: 100%;
    }

    .slider {
      width: 100%;
    }

    h2 {
      color: #465EFF;
      font-family: "BancoDoBrasilTitulosBold700";
      font-size: 36px;
      line-height: 36px;
      margin: 0;

      @media only screen and (min-width: 600px) {
        font-size: 48px;
        line-height: 48px;
      }
    }

    p {
      color: #465EFF;
      margin-top: 2rem;

      @media only screen and (min-width: 600px) {
        width: 50%;
      }
    }

    span {
      color: #465EFF;
      text-align: center;
      margin-top: 6rem;
      margin-bottom: 4rem;
      font-size: 14px;

      @media only screen and (min-width: 600px) {
        width: 50%;
      }
    }

    button {
      background-color: #465EFF;
      outline: none;
      border: none;
      border-radius: 10px;
      color: #FCFC30;
      font-size: 24px;
      padding: 0.5rem 2rem;
      margin-bottom: 3rem;
      width: 75%;

      @media only screen and (min-width: 600px) {
        border-radius: 20px;
        padding: 1rem 4rem;
        margin-bottom: 6rem;
        width: 35%;
      }
    }
    
  }

  .bottom{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.slick-list {
  overflow: visible !important;
  .slick-track {
    > div {
      padding-right: 3rem;
    }
  }
}

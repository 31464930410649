.page-quiz {
  width: 100vw;
  height: auto;
  min-height: var(--height);
  position: relative;
  .bottom {
    position: absolute;
    bottom: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.filterLoading {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingMask {
    height: 50px;
}


.navbar-light .navbar-nav .menuPrincipal .nav-link.active {
  color: #af0c16 !important;
}
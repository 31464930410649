.component-logo-bottom {
  path {
    &:first-child {
      fill: transparent;
    }
  }
  &.inverter {
    path {
      fill: #FCFC30;
      &:first-child {
        fill: transparent;
      }
    }
  }
}

*{
  box-sizing: border-box;
}

.alert{
  margin: 20px 0px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background: #ffaaaa;
  border: solid 1px #ab1212;
  color: #ab1212;
  &.error{
    background: #ffaaaa;
    border: solid 1px #ab1212;
    color: #ab1212;
  }
  &.success{
    background: #b3ffcb;
    border: solid 1px #1d5224;
    color: #1d5224;
  }
}

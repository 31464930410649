.content-parabens {
  animation-name: bgContent;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  position: relative;
  .animation {
    width: 100vw;
    height: var(--height);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .dividir {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(var(--height) / 2);
      position: relative;

      @for $i from 1 through 6 {
        .color:nth-child(#{$i}) {
          height: #{$i * 16.666666666666667%};
        }
      }

      @for $i from 1 through 7 {
        .color2:nth-child(#{$i}) {
          height: #{$i * 14.285714285714286%};
        }
      }

      .color {
        width: 100%;
        opacity: 1;
        flex-basis: 100%;
        transform: rotate(180deg);
        animation-name: squareToTriangle;
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;

        @media only screen and (min-width: 1024px) {
          animation-name: squareToTriangleResponsive;
        }
      }
      .color2 {
        width: 100%;
        opacity: 1;
        flex-basis: 100%;
        transform: rotateY(180deg);
        animation-name: squareToTriangle2;
        animation-duration: 1.4s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;

        @media only screen and (min-width: 1024px) {
          animation-name: squareToTriangle2Responsive;
        }
      }
    }
    .content-home {
      position: absolute;
      width: 100vw;
      height: var(--height);
      overflow: hidden;
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;
      z-index: 7;
      flex-wrap: wrap;
      opacity: 0;
      animation-name: showContent;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;

      @media only screen and (min-width: 800px) {
        padding: 0 4rem;
      }

      @media only screen and (min-width: 1100px) {
        padding: 0 8rem;
      }

      @media only screen and (min-width: 1600px) {
        padding: 0 10rem;
      }

      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
          font-family: "BancoDoBrasilExtraBoldItalic";
          font-size: 20px;
          line-height: 22px;
          margin-bottom: 1.5rem;
          text-align: center;
          color: #465EFF;

          @media only screen and (min-width: 800px) {
            font-size: 48px;
            line-height: 50px;
          }

          @media only screen and (min-width: 1600px) {
            font-size: 48px;
            line-height: 50px;
          }
        }
        h3 {
          font-family: "BancoDoBrasilTitulosLightItalic";
          font-size: 24px;
          line-height: 24px;
          color: #465EFF;

          @media only screen and (min-width: 1024px) {
            font-size: 42px;
            line-height: 42px;
          }

          @media only screen and (min-width: 1600px) {
            font-size: 80px;
            line-height: 80px;
          }
        }
        b {
          font-family: "BancoDoBrasilTitulosBold700";
        }
        .you {
          font-size: 36px;
          line-height: 24px;
          padding-left: 2.5rem;

          @media only screen and (min-width: 1024px) {
            font-size: 64px;
            padding-left: 4.2rem;
          }

          @media only screen and (min-width: 1600px) {
            font-size: 96px;
            padding-left: 11rem;
          }
        }
        .button-wrapper {
          position: relative;
          width: 80%;
          margin: 2rem 0;

          @media only screen and (min-width: 1024px) {
            margin: 2.5rem 0;
          }

          @media only screen and (min-width: 1600px) {
            margin: 3.5rem 0;
          }

          button {
            cursor: pointer;
            border: 1px solid #465EFF;
            border-radius: 10px;
            background: #465EFF;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #fcfc30;
            padding: 0.7rem;
            width: 100%;
            font-family: "BancoDoBrasilTitulosBold700";

            @media only screen and (min-width: 1024px) {
              font-size: 32px;
              line-height: 32px;
              padding: 1rem 2.5rem;
            }
          }
        }
      }
      .person {
        position: absolute;
        bottom: 180px;
        right: -80px;
        width: 82%;
        z-index: -2;
        img {
          width: 100%;
        }

        @media only screen and (min-width: 800px) {
          bottom: 290px;
          right: -70px;
          width: 65%;
        }

        @media only screen and (min-width: 1100px) {
          bottom: 150px;
          right: -20px;
          width: 40%;
        }

        @media only screen and (min-width: 1280px) {
          bottom: 120px;
          right: 150px;
          width: 40%;
        }

        @media only screen and (min-width: 1600px) {
          right: 350px;
          bottom: 180px;
          width: 35%;
        }
      }
    }
  }

  figure {
    svg {
      @media only screen and (min-width: 1024px) {
        width: 180px;
        height: 180px;
      }
    }
  }
}

@keyframes slideAndScaleAnimation {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    transform: scaleX(0.5);
    flex-basis: 100%;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
    transform: scaleX(1);
    flex-basis: 40%;
  }
}

@keyframes abrirColors {
  0% {
    width: 75%;
  }
  100% {
    width: 20%;
    z-index: 3;
  }
}
@keyframes abrirColorsColum2 {
  0% {
    width: 25%;
  }
  100% {
    width: 6%;
    z-index: 1;
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
@keyframes bgContent {
  100% {
    background: #fcfc30;
  }
}
@keyframes bottomAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes abrirColorsResponsive {
  0% {
    width: 75%;
  }
  100% {
    width: 8%;
    z-index: 3;
  }
}

@keyframes abrirColorsColum2Responsivo {
  0% {
    width: 25%;
  }
  100% {
    width: 2%;
    z-index: 1;
  }
}

@keyframes squareToTriangle {
  0% {
    clip-path: polygon(0 0, 100% 0, 1% 100000%);
  }
  100% {
    clip-path: polygon(0 5%, 100% 100%, 0 100%);
  }
}

@keyframes squareToTriangleResponsive {
  0% {
    clip-path: polygon(0 0, 100% 0, 1% 100000%);
  }
  100% {
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
}

@keyframes squareToTriangle2 {
  0% {
    clip-path: polygon(0 0, 0 100000%, 100% 0);
  }
  100% {
    clip-path: polygon(0 20%, 0 100%, 150% 100%);
  }
}

@keyframes squareToTriangle2Responsive {
  0% {
    clip-path: polygon(0 0, 0 100000%, 100% 0);
  }
  100% {
    clip-path: polygon(0 0, 0 100%, 100% 100%);
  }
}

@media only screen and (min-width: 600px) {
  .content-parabens .animation .dividir {
    animation-name: abrirColorsResponsive;
  }
  .content-parabens .animation .dividir:last-child {
    animation-name: abrirColorsColum2Responsivo;
  }
}
